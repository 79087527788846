<template>
  <div>
    <PageTitle
      title="會員權益參數設定"
      hideBtn
      icon="chevron_left"
      @iconClick="$router.push({ name: 'Parameters' })"
    />

    <div>
      <SubscriptionPlanPeriodicBenefitIssueSetting />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import SubscriptionPlanPeriodicBenefitIssueSetting from './components/SubscriptionPlanPeriodicBenefitIssueSetting.vue'
export default defineComponent({
  name: 'PeriodicBenefitParameters',
  components: {
    PageTitle,
    SubscriptionPlanPeriodicBenefitIssueSetting,
  },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>

</style>
