<template>
  <div class="section-block">
    <SectionTitle style="margin-bottom: 32px" title="訂閱方案權益發放設定" hideBtn />

    <BaseElForm label-position="top">
      <BaseElFormItem label="訂閱扣款成功才發放當期會員權益" prop="issueType">
        <div>
          <BaseElSwitch
            v-model="formData.enable"
            active-text="開啟"
            inactive-text="關閉"
            @change="onEnableChange"
          />

          <GrayBlockContainer>
            若開啟上方設定，則套用下方描述：
            <ol class="list-disc pl-[20px]">
              <li>訂閱方案扣款時機為「使用前付費」時，判定發放當下以前的最後一筆訂閱扣款紀錄，「已扣款」才發放會員權益</li>
              <li>訂閱方案扣款時機為「使用後付費」時，若會員權益範本啟用「會員獲得會員權益當下，立即發放第一次」，則第一次直接發放會員權益，之後的判定如同「使用前付費」；若會員權益範本不啟用「會員獲得會員權益當下，立即發放第一次」，則每次發放會員權益的判定如同「使用前付費」</li>
            </ol>
          </GrayBlockContainer>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useBaseForm } from '@/use/useForm'
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { UpdatePeriodicBenefitConfig, GetPeriodicBenefitConfig } from '@/api/periodicBenefit'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'SubscriptionPlanPeriodicBenefitIssueSetting',
  components: {
    SectionTitle,
    GrayBlockContainer,
  },
  setup (props) {
    const { formRef, formData, initFormData, loading } = useBaseForm()
    const { shopId } = useShop()
    const configData = ref({})

    initFormData({
      enable: false,
    })

    const refresh = async () => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await GetPeriodicBenefitConfig({
        shopId: shopId.value,
      })
      configData.value = res
      loading.value = false
    }

    const syncFormData = () => {
      formData.enable = configData.value.subscriptionDecideAward
    }

    const onEnableChange = async () => {
      if (loading.value) return
      loading.value = true
      const [, err] = await UpdatePeriodicBenefitConfig({
        shopId: shopId.value,
        subscriptionDecideAward: formData.enable,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      refresh()
    }

    onBeforeMount(async () => {
      await refresh()
      syncFormData()
    })

    return { formRef, formData, configData, onEnableChange }
  },
})
</script>

<style lang="postcss" scoped>

</style>
